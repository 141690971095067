/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import FeatureToggleForm from './FeatureToggles';

import LoadingScreen from '../screen/Loading';
import { setIsLoading } from '../../redux/loadingSlice';

import useApiCall from '../../functions/useApiCall';

import FullScreenWrapper from '../utils/FullscreenWrapper';

// eslint-disable-next-line react/prop-types
const FeatureDashboard = ({ user, onClose }) => {
  const dispatch = useDispatch();

  const { apiFetch } = useApiCall();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');

  const isLoading = useSelector((state) => state.loading.isLoading);

  const [publicFeatures, setPublicFeatures] = useState([]);

  const getPublicFeatures = async (userEmail, userUid) => {
    const publicFeatureData = { email: userEmail, token: userUid };
    const action = 'availablePublicFeatures';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, publicFeatureData);
    try {
      const publicFeatureResponse = response;
      const publicFeatureNames = publicFeatureResponse.map(feature => feature.name);
      setPublicFeatures(publicFeatureNames);
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (user) {
      if (publicFeatures.length < 1) {
        // eslint-disable-next-line react/prop-types
        getPublicFeatures(user.email, user.uid);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <FullScreenWrapper>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <h2>Hi, {user.email}</h2>
          <FeatureToggleForm user={user} publicFeatures={publicFeatures} onClose={onClose} />
        </>
      )}
    </FullScreenWrapper>
  );
};

export default FeatureDashboard;
