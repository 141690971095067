import React from 'react';
import loaderStyle from "../../styles/Loader.module.css";

const Loading = () => {
  return (
    <div id="loading-screen" className={loaderStyle.loader}>
      <div className={loaderStyle.shuriken}></div>
      Loading
    </div>
  );
};

export default Loading;
