import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleRight } from 'react-icons/fa';

import Toggle from 'react-toggle'
import 'react-toggle/style.css'; // NOTE: Dependency in FeatureToggle; if this is removed uncomment that

import styles from '../../styles/Register.module.scss';

const StudentContact = ({ handleInputChange, handleNext, studentInfo }) => {

  return (
    <>
      <h1 className={`shadow`}>Student Contact Information</h1>
      <div className={styles.formFullWidthComponents}>

        <label>
          <Toggle
            id="hasNoEmail"
            name="hasNoEmail"
            defaultChecked={studentInfo.email.hasNoEmail}
            onChange={(e) => handleInputChange('hasNoEmail', e.target.value)}
          />
          <span className={styles.toggleLabelText}>
            Use emergency contact email address<br />(Student is a minor with no email)
          </span>
        </label>

        <label>
          Student E-mail address
          <input
            type="text"
            id="email"
            name="email"
            value={studentInfo.email.value}
            onChange=
              {(e) => handleInputChange('email', e.target.value)}
              style={{ borderColor: studentInfo.email.isValid ? 'green' : 'red' }}
              required
          />
        </label>
        <div className={styles.errorLabel}>{studentInfo.email.errorMessage}</div>

        <label>
          <Toggle
            id="hasNoPhone"
            name="hasNoPhone"
            defaultChecked={studentInfo.email.hasNoPhone}
            onChange={(e) => handleInputChange('hasNoPhone', e.target.value)}
          />
          <span className={styles.toggleLabelText}>
            Use emergency contact phone number<br />(Student is a minor with no phone)
          </span>
        </label>

        <label>
          Student Phone number (Local to NZ)
          <input
            type="text"
            id="phone"
            name="phone"
            value={studentInfo.phone.value}
            onChange={(e) => handleInputChange('phone', e.target.value)}
            style={{ borderColor: studentInfo.phone.isValid ? 'green' : 'red' }}
            required
          />
        </label>
        <div className={styles.errorLabel}>{studentInfo.phone.errorMessage}</div>

      </div>
      <button type="button" onClick={handleNext} className={styles.nextButton}>Next <FaAngleRight /></button>
    </>
  );
};

StudentContact.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  studentInfo: PropTypes.shape({
    email: PropTypes.shape({
      value: PropTypes.string.isRequired,
      hasNoEmail: PropTypes.bool.isRequired,
      isValid: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string.isRequired,
      hasNoPhone: PropTypes.bool.isRequired // Assuming these are boolean props
    }).isRequired,
    phone: PropTypes.shape({
      value: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default StudentContact;