import React from 'react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const ImageGridItem = ({ to, imgSrc, imgAlt, title, description, isWide }) => {

  const itemClassName = isWide ? "wideItem" : "menuItem";

  return (
    <Link to={to} className={itemClassName}>
      <img src={imgSrc} alt={imgAlt} />
      <h2 className="shadow">{title}</h2>
      <p>{description}</p>
    </Link>
  );
};

export default ImageGridItem;
