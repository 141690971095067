import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleRight } from 'react-icons/fa';

import Toggle from 'react-toggle'
import 'react-toggle/style.css';

import styles from '../../styles/Register.module.scss';

const Equipment = ({ handleInputChange, handleNext, studentInfo }) => {


  return (
    <>
      <h1 className={`shadow`}>Student Equipment Information</h1>
      <div className={styles.formFullWidthComponents}>

        <label>
          <Toggle
            id="requireGi"
            name="requireGi"
            defaultChecked={studentInfo.requireGi.value}
            onChange={(e) => handleInputChange('requireGi', e.target.value)}
          />
          <span className={styles.toggleLabelText}>
            Student requires a training uniform (Japanese name: Gi)
          </span>
        </label>

        <label className="inputWrapper">
          Student height for uniform
          <input
            type="text"
            id="giHeight"
            name="giHeight"
            value={studentInfo.giHeight.value}
            onChange={(e) => handleInputChange('giHeight', e.target.value)}
            style={{ borderColor: studentInfo.giHeight.isValid ? 'green' : 'red' }}
            required
          />
          <span className="unit">cm</span>
        </label>
        <div className={styles.errorLabel}>{studentInfo.giHeight.errorMessage}</div>

      </div>
      <button type="button" onClick={handleNext} className={styles.nextButton}>Next <FaAngleRight /></button>
    </>
  );
};

Equipment.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  studentInfo: PropTypes.shape({
    requireGi: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
    giHeight: PropTypes.shape({
      value: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default Equipment;