import React, { useState } from 'react';

const ZoomControls = () => {
    const [zoomLevel, setZoomLevel] = useState(100);

    const handleZoomIn = () => {
        if (zoomLevel < 200) {
            setZoomLevel(prevZoomLevel => prevZoomLevel + 10);
            document.documentElement.style.zoom = `${zoomLevel + 10}%`;
        }
    };

    const handleZoomOut = () => {
        if (zoomLevel > 50) {
            setZoomLevel(prevZoomLevel => prevZoomLevel - 10);
            document.documentElement.style.zoom = `${zoomLevel - 10}%`;
        }
    };

    return (
        <div className="zoomControls" style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}>
            <button onClick={handleZoomIn}>+</button>
            <button onClick={handleZoomOut}>-</button>
        </div>
    );
};

export default ZoomControls;
