/* INFO: Custom hook convenience function for server api calls */

const useApiCall = () => {
  // if you are using a server identifier, prepend it and a slash to the endpoit
  // also amend server as used in App.js for routing, CardGallery, package.json, and manifest.json
  const endpoint = 'jks';
  //const vapidPublicKey = 'BIczuUU79jFuO0kw_Gfi9EwU9eWc4VIj_xlVkmCkvtb4Um8n-gHXRf2UOpcGiJHAl8qAB6x2lCuyWn3phkfRkeU';

  const isProd = true; /* IMPORTANT NOTE: set this to true before build */
  
  const devOrProdUrl = (isProd = true) => {    
    if (isProd) {
      const host = 'hpd.nz';
      /* no port - prod routing handles this */
      return `https://${host}/${endpoint}`;
    } else {
      const host = 'localhost';
      const port = '4000';
      return `https://${host}:${port}/${endpoint}`;
    }
  }
  const url = devOrProdUrl(isProd) || devOrProdUrl();

  const apiFetch = async (action, bodyData) => {
    const jsonBodyData = JSON.stringify(bodyData);

    try {
      const response = await fetch(`${url}/${action}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include credentials for CORS requests
        body: jsonBodyData,
      });

      const result = response;
      if (!result.ok) {
        throw new Error(`API Fetch error: ${result.status}`);
      }
      return result.json();      

    } catch (error) {  
      return error;
    }
  };

  const apiGet = async (action) => { 
    try {
      const response = await fetch(`${url}/${action}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include credentials for CORS requests
        timeout: 10000,        
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else {
        return response.statusText; // OK
      }      
    } catch (error) {
      return 'Network response was not ok';
    }
  };

  // add additional api methods below

  return { apiGet, apiFetch };
};

export default useApiCall;