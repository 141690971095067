import React, { useState, useEffect } from 'react';
import CardThumbnail from './CardThumbnail';

// eslint-disable-next-line react/prop-types
const CardGallery = ({ student, onCardSelect }) => {
  const server = process.env.REACT_APP_PATH || ''; // also used in useApiCall, CardGallery, package.json, and manifest.json
  const serverRoute = (server.length > 0) ? `/${server}/` : '';

  const [cardIds, setCardIds] = useState([]);

  // eslint-disable-next-line react/prop-types
  const [selectedCardUrl, setSelectedCardUrl] = useState();
  // eslint-disable-next-line react/prop-types
  const studentFirstName = student.firstname || 'Student';
  
  // eslint-disable-next-line react/prop-types, no-unused-vars
  const [selectedCardId, setSelectedCardId] = useState();

  const extractCardIdFromUrl = (url) => {
    const match = url.match(/ids\/(\d+)\/front\.png/);
    if (match) {
        return parseInt(match[1], 10);
    }
    return 0;
  };

  const handleCardClick = (imageUrl) => {
    setSelectedCardUrl(imageUrl);
    console.debug(`imageUrl: [${imageUrl}]`);
    const newCardId = extractCardIdFromUrl(imageUrl);
    console.debug(`New Card ID: [${newCardId}]`);
    // eslint-disable-next-line react/prop-types
    onCardSelect(newCardId);
  };

  useEffect(() => {
    if (selectedCardId) {
      console.debug(`Selected Card ID: [${selectedCardId}]`);
      setSelectedCardUrl(`${serverRoute}ids/${selectedCardId}/front.png`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCardId]);

  useEffect(() => {
    const ids = [1, 2, 3, 4, 5, 6, 7, 8, 9]; // TODO: Replace with dynamic card IDs
    setCardIds(ids);
    // eslint-disable-next-line react/prop-types
    if (student && student.cardId) {
      // eslint-disable-next-line react/prop-types
      setSelectedCardId(student.cardId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h2>{studentFirstName}&apos;s Student ID Card Settings</h2>
      <h2>Current Card
        {selectedCardId && selectedCardUrl &&
        <CardThumbnail
          key={`selected`}
          imageUrl={selectedCardUrl}
          onClick={handleCardClick}
        />}
      </h2>
      <h2>Available Cards</h2>
      <div>
        {cardIds.map((id) => (
          <CardThumbnail
            key={id}
            imageUrl={`${serverRoute}ids/${id}/front.png`}
            onClick={handleCardClick}
            isSelected={selectedCardUrl === `${serverRoute}ids/${id}/front.png`}
          />
        ))}
      </div>
    </>
  );
};

export default CardGallery;
