import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CreateMessageForm from './CreateMessage';
import SendMessageForm from './SendMessage';

import useApiCall from '../../functions/useApiCall';

import { setIsLoading } from '../../redux/loadingSlice';

import LoadingScreen from '../screen/Loading';
import FullScreenWrapper from '../utils/FullscreenWrapper';

// eslint-disable-next-line react/prop-types
const MessagingDashboard = ({ user, onClose }) => {
  const dispatch = useDispatch();

  const { apiFetch } = useApiCall();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');

  const isLoading = useSelector((state) => state.loading.isLoading);

  const putNewMessage = async (newMessageData) => {
    // eslint-disable-next-line react/prop-types
    newMessageData.email = user.email;
    // eslint-disable-next-line react/prop-types
    newMessageData.token = user.uid;
    const action = 'newMessage';
    dispatch(setIsLoading(true));
    await apiFetch(action, newMessageData);
    dispatch(setIsLoading(false));
  };

  const sendNewMessage = async (sendableMessageData) => {
    // eslint-disable-next-line react/prop-types
    sendableMessageData.email = user.email;
    // eslint-disable-next-line react/prop-types
    sendableMessageData.token = user.uid;
    const action = 'sendMessage';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, sendableMessageData);
    if (response.affectedRows == 1 && response.serverStatus == 2) {
      console.log(`Succesfully sent message`);
      // TODO: Add toast confirmation of successful message sent
    } else {
      console.error(response);
      // TODO: Add error notification of failed message sent
    }
    dispatch(setIsLoading(false));
  };
  
  return (
    <FullScreenWrapper>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <CreateMessageForm onSubmit={putNewMessage} onClose={onClose} />
          <SendMessageForm user={user} onSubmit={sendNewMessage} onClose={onClose} />
        </>
      )}
    </FullScreenWrapper>
  );
};

export default MessagingDashboard;
