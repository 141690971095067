import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/IconMessage.module.scss';

const IconMessage = ({ icon: Icon, heading, description, type = "default" }) => {
  let containerStyle = styles.iconMessage; // default
  // see available styles in css
  // eslint-disable-next-line default-case
  switch (type) {
    case 'offer':
      containerStyle += ` ${styles.offer}`;
      break;
  }
  return (
    <div className={containerStyle}>
      <Icon className={styles.icon} />
      <div>
        <h2>{heading}</h2>
        <p className={`shadow`}>{description}</p>
      </div>
    </div>
  );
};

IconMessage.propTypes = {
  icon: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  type: PropTypes.node.isRequired,
};

export default IconMessage;