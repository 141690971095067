import React from 'react';
import Chart from 'react-apexcharts';
import chartBaseOptions from '../chartBaseOptions';

// eslint-disable-next-line react/prop-types
const DojoUsers = ({ initialData }) => {
  // eslint-disable-next-line react/prop-types
  const userData = initialData.map((item) => ({
    location: item.location,
    users: item.user_count,
  }));  
  const locations = userData.map((data) => data.location); // extract x-axis (locations)
  const userCounts = userData.map((data) => data.users); // extract y-axis (user counts)

  const options = {
    ...chartBaseOptions,
    title: {
      text: 'Dojos - Users per Dojo',
    },
    xaxis: {
      // type: 'text',
      categories: locations, // use text array for x-axis labels
    },
    yaxis: {
      title: {
        text: "Monthly Active Users",
      },
    },
  };
  
  const series = [
    {
      name: "Users",
      data: userCounts,
    }
  ];

  return (
    <Chart options={options} series={series} type="area" height={320} width={480} />
  );
}

export default DojoUsers;
