import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingScreen from '../screen/Loading';
import { setIsLoading } from '../../redux/loadingSlice';

import useApiCall from '../../functions/useApiCall';

import FullScreenWrapper from '../utils/FullscreenWrapper';

import { FaEnvelope, FaPhone, FaTimes, FaUserNinja } from 'react-icons/fa';
import styles from '../../styles/Profile.module.scss';

// eslint-disable-next-line react/prop-types
const UserDojo = ({ user, onClose }) => {
  const { apiFetch } = useApiCall();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.auth.userInfo);

  const [homeDojo, setHomeDojo] = useState([]);
  const [mapWidth, setMapWidth] = useState();
  const [mapHeight, setMapHeight] = useState();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');

  const isLoading = useSelector((state) => state.loading.isLoading);

  const sizeIframe = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    setMapWidth(screenWidth * 0.8);
    setMapHeight(screenHeight * 0.3);
  }

  const fetchHomeDojoInformation = async (locationName, userEmail, userUid) => {
    const userData = { location: locationName, email: userEmail, token: userUid };
    const action = 'dojoDetails';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, userData);
    try {
      const homeDojo = response[0];
      dispatch(setHomeDojo(homeDojo));
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (user && userInfo && userInfo.location) {
      // eslint-disable-next-line react/prop-types
      fetchHomeDojoInformation(userInfo.location, user.email, user.uid);
      sizeIframe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullScreenWrapper>
      {isLoading ? (
        <LoadingScreen />
      ) : (homeDojo &&
        <div className={styles.formComponents}>
          <h1 className={`shadow`}>Dojo Details</h1>
          <div className={styles.container}>

            <h1>{userInfo.location} Dojo</h1>

            <p className={styles.info}><FaUserNinja className={styles.icon} />{homeDojo.contact_name_surname}</p>
            <p className={styles.info}>
              <a href={`tel:${homeDojo.contact_phone_number}`} className={styles.button}>
                <FaPhone className={styles.icon} />{homeDojo.contact_phone_number}
              </a>
            </p>
            <p className={styles.info}>
              <a href={`mailto:${homeDojo.contact_email_address}`} className={styles.button}>
                <FaEnvelope className={styles.icon} />{homeDojo.contact_email_address}
              </a>    
            </p>

            <p className={styles.info}>
              <a href={homeDojo.map_link} className={styles.button}>
                {homeDojo.address}
              </a>
            </p>
            <p className={styles.map} style={{ height: '30vh', paddingTop: '2rem' }}>
              <a href={homeDojo.map_link}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d51127.342861616096!2d174.7370925!3d-36.7835456!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d39d867a88e71%3A0x18a1bc8165273be6!2s11%20Killarney%20Street%2C%20Takapuna%2C%20Auckland%200622!5e0!3m2!1saf!2snz!4v1725513717408!5m2!1saf!2snz" width={`${mapWidth}px`} height={`${mapHeight}px`} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </a>
            </p><br />
            
            <button type="submit" className={styles.closeButton} onClick={onClose}>
              <span className="shadow">Close</span>
            </button>

          </div>

          <div className={`${error ? styles.errorContainer : styles.errorPlaceholder}`}>
            {error ? (
              <><FaTimes className={styles.icon} /><span className={styles.error}>{error}</span></>
            ) : (
              <></>
            )}
          </div>

        </div>
      )}
    </FullScreenWrapper>
  );
};

export default UserDojo;