import { useEffect } from "react";
import { toast } from "react-toastify";

const ConnectionMonitor = () => {
  const offlineMessage = `Device is offline, please re-establish connectivity`;
  const onlineMessage = `Device is back online :)`;

  useEffect(() => {
    const handleOffline = () => {
      toast.error(offlineMessage);
      console.error(offlineMessage);
    }
    const handleOnline = () => {
      toast.success(onlineMessage);
      console.error(onlineMessage);
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  return null; // doesn't render anything
};

export default ConnectionMonitor;