import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 0,
};

const signUpSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    setSignUpStep: (state, action) => {
      state.step = action.payload;
    },
  },
});

export const { setSignUpStep } = signUpSlice.actions;
export default signUpSlice.reducer;