import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { auth } from '../../firebase/firebase';
import { signOut } from "firebase/auth";
import { clearUser, setTheme, setUserInfo } from '../../redux/authSlice';
import { clearStudents, setStudents } from '../../redux/studentInfoSlice';

import { setUnreadMessages } from '../../redux/messageSlice';

import { setError } from '../../redux/errorSlice';

import LoadingScreen from '../screen/Loading';
import { setIsLoading } from '../../redux/loadingSlice';
import { setFeatureStatus } from '../../redux/featuresSlice';

import ConnectionMonitor from '../../ConnectionMonitor';
import useApiCall from '../../functions/useApiCall';

import IdCardButton from './IdCardButton';

import Dojo from '../user/UserDojo';
import StudentId from '../student/StudentId';
import Settings from '../settings/Settings'; // This is user's settings
import UserInfo from '../user/UserInfo';
import UserInbox from '../user/UserInbox'; // This is user's receive message inbox
import ReportOfUsers from '../reporting/users/ReportOfUsers';

import MessagingDashboard from '../admin/MessagingDashboard'; // This is admin's send message dashboard
import Footer from './Footer';

import { FaBug, FaUserNinja, FaMapMarkerAlt, FaPaperPlane, FaComment, FaSwatchbook, FaChartPie, FaQrcode } from 'react-icons/fa';
import loginStyles from '../../styles/Login.module.scss';
import profileStyles from '../../styles/Profile.module.scss';
import FeatureDashboard from '../app manager/FeatureDashboard';
import QRCodeScanner from '../qr/QRCodeScanner';

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isError = useSelector((state) => state.error.isError);

  const isLoading = useSelector((state) => state.loading.isLoading);

  const { apiFetch } = useApiCall();

  /* NOTE: These are the feature toggles - update these when updating features migration */
  // const isPushMessagingFeatureEnabled = useSelector((state) => state.feature.pushMessagingFeature);
  const isMessagingFeatureEnabled = useSelector((state) => state.feature.messagingFeature);
  const isHomeDojoFeatureEnabled = useSelector((state) => state.feature.homeDojoFeature);
  const isStudentIdFeatureEnabled = useSelector((state) => state.feature.studentIdFeature);
  const isReportingFeatureEnabled = useSelector((state) => state.feature.reportingFeature);
  const isQrScanFeatureEnabled = useSelector((state) => state.feature.qrScanFeature);
  const featuresToCheck = ['messagingFeature', 'homeDojoFeature', 'studentIdFeature', 'reportingFeature', 'qrScanFeature'];

  const user = (auth && auth.currentUser) ? auth.currentUser : null;
  const userType = useSelector((state) => state.auth.theme);  
  const userInfo = useSelector((state) => state.auth.userInfo);

  const students = useSelector((state) => state.students.students);
  const  [currentStudent, setCurrentStudent] = useState();

  const [showSettings, setShowSettings] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);

  /* This is user's receive message inbox */
  const messageCount = useSelector((state) => state.messages.messages.length) || 0;
  const [isMessagingVisible, setIsMessagingVisible] = useState(false);

  const [showDojo, setShowDojo] = useState(false);
  const [showStudentId, setShowStudentId] = useState(false);
  const [showAdminMessaging, setShowAdminMessaging] = useState(false); // This is admin's send message dashboard
  const [showFeatureToggleDashboard, setShowFeatureToggleDashboard] = useState(false);
  const [showReporting, setShowReporting] = useState(false);
  const [showQrScanner, setShowQrScanner] = useState(false);

  const toggleSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  const toggleUserInfoClick = () => {
    setShowUserInfo(!showUserInfo);
  };

  const toggleDojoClick = () => {
    setShowDojo(!showDojo);
  };

  const toggleStudentIdClick = () => {
    setShowStudentId(!showStudentId);
  };

  const toggleMessagingDashboardClick = () => {
    setShowAdminMessaging(!showAdminMessaging);
  };

  const toggleMessagingClick = () => {
    setIsMessagingVisible(!isMessagingVisible);    
  };

  const toggleFeatureDashboardClick = () => {
    setShowFeatureToggleDashboard(!showFeatureToggleDashboard);
  };

  const toggleReportingClick = () => {
    setShowReporting(!showReporting);
  };

  const toggleQrScannerClick = () => {
    setShowQrScanner(!showQrScanner);
  };

  const fetchProfile = async () => {
    fetchThemeInformation(user.email, user.uid);
    fetchUserInformation(user.email, user.uid);
    // the rest depends on userInfo so we're calling it from the useEffect
  };

  const clearProfile = async () => {
    dispatch(clearUser());
    dispatch(clearStudents());
    navigate("/");
  }

  const refreshProfile = () => {
    dispatch(setIsLoading(true));
    if (user) {
      fetchProfile();
    } else {
      clearProfile();
    }          
    dispatch(setIsLoading(false));
  }

  const doLogOut = async () => {
    dispatch(setIsLoading(true));
    try {
      await signOut(auth);
      clearProfile();
    } catch {
      console.error(`Error logging out`);
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  const logOut = (e) => {
    e.preventDefault();
    doLogOut();
  }

  const fetchThemeInformation = async (userEmail, userUid) => {
    const themeData = { email: userEmail, token: userUid };
    const action = 'theme';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, themeData);
    try {
      const userThemeWithNoSpaces = (response[0].name).replace(/\s+/g, '');
      dispatch(setTheme(userThemeWithNoSpaces));
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  const fetchUserInformation = async (userEmail, userUid) => {
    const userData = { email: userEmail, token: userUid };
    const action = 'userDetails';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, userData);
    try {
      dispatch(setUserInfo(response[0][0]));
    } catch (error) {
      // ERROR: Something must've gone wrong with user registration on the local DB
      console.error(error);     
      dispatch(setError('Could not find user'));
    }
    dispatch(setIsLoading(false));
  };

  const fetchStudentInformation = async (userEmail, userUid) => {
    dispatch(clearStudents());
    // eslint-disable-next-line react/prop-types
    const studentData = { email: userEmail, token: userUid };

    const action = (userInfo.role === "trusted adult") ? 'studentDependentDetails' : 'studentDetails';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, studentData);
    try {
      dispatch(setStudents(response[0]));
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  const fetchUnreadMessages = async (userEmail, userUid) => {
    // eslint-disable-next-line react/prop-types
    const unreadMessageData = { email: userEmail, token: userUid };
    const action = 'unreadMessages';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, unreadMessageData);

    try {
      dispatch(setUnreadMessages(response)); /* we need to set this to get the amount */
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  const fetchProfileFeature = async (featureName) => {
    const featureData = { feature: featureName };
    const action = 'privateFeature';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, featureData);
    if (response && response[0]) {
      const isFeatureEnabled = (response[0].isActive) ? true : false;
      dispatch(setFeatureStatus({ featureName, status: isFeatureEnabled }));
    } else {
      console.debug(`Features not found, using defaults`);
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (user && isMessagingFeatureEnabled && !isMessagingVisible) {
      fetchUnreadMessages(user.email, user.uid);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagingVisible]);

  useEffect(() => {
    if (user && userInfo) {
      featuresToCheck.forEach((feature) => {
        fetchProfileFeature(feature);
      });
  
      fetchStudentInformation(user.email, user.uid);
      fetchUnreadMessages(user.email, user.uid);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (isError.length > 0) {
      console.error(isError);
    }
  }, [isError]);

  useEffect(() => {
    refreshProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {user &&
        <>
          <form>
            <h2 className="shadow">
              <div><FaUserNinja className={loginStyles.icon} onClick={refreshProfile}/></div>
              Hi, {userInfo ? (
                <>{userInfo.firstname} {userInfo.surname}</>
              ) : (
                <>{user.email}</>
              )}              
            </h2>
            <div className={loginStyles.logoutButtonContainer}>
              <button type="submit" className={loginStyles.authButton} onClick={(e) => logOut(e)}>
                <span className="shadow">Logout</span>
              </button>
            </div>
          </form>
          <div style={{height: '1em'}} />      
        
          <>
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <></>
            )}

            {showStudentId && <StudentId student={currentStudent} onClose={toggleStudentIdClick} />}
    
            {userInfo ? (
              <div className={`profileItems`}>
                {isHomeDojoFeatureEnabled &&
                  <>
                    <div className={`menuItem`} onClick={toggleDojoClick}>
                      <FaMapMarkerAlt className={`icon`} />
                      <h2 className={`shadow`}>{userInfo.location}</h2>
                    </div>
                    {showDojo && <Dojo user={user} onClose={toggleDojoClick} />}
                  </>
                }
      
                {isStudentIdFeatureEnabled && students && students.map((student) => ((student &&
                    <IdCardButton
                      key={student.id}
                      student={student}
                      setCurrentStudent={setCurrentStudent}
                      toggleStudentIdClick={toggleStudentIdClick}
                    />
                  )))
                }
      
                {isMessagingFeatureEnabled &&
                  <>
                    <div className={`menuItem`} onClick={toggleMessagingClick}>
                      <FaComment className={`icon`} />
                      <h2>{(messageCount < 1 ? 'No' : messageCount)} New {(messageCount === 1 ? 'Message' : 'Messages')}</h2>
                    </div>
                    {isMessagingVisible && <UserInbox user={user} onClose={toggleMessagingClick} />}
                  </>
                }
      
                {(userType === 'admin' || userType === 'appmanager') &&
                  <>
                    <div className={`menuItem`} onClick={toggleMessagingDashboardClick}>
                      <FaPaperPlane className={`icon`} />
                      <h2>Messaging</h2>
                    </div>
                    {showAdminMessaging && <MessagingDashboard user={user} onClose={toggleMessagingDashboardClick} />}

                    {isReportingFeatureEnabled &&
                      <>
                        <div className={`menuItem`} onClick={toggleReportingClick}>
                          <FaChartPie className={`icon`} />
                          <h2>Reporting</h2>                          
                        </div>
                        {showReporting && <ReportOfUsers user={user} onClose={toggleReportingClick} />}
                      </>
                    
                    }

                    {isQrScanFeatureEnabled && 
                      <>
                        <div className={`menuItem`} onClick={toggleQrScannerClick}>
                          <FaQrcode className={`icon`} />
                          <h2>QR Code Scanner</h2>
                        </div>
                        {showQrScanner && <QRCodeScanner user={user} onClose={toggleQrScannerClick} />}
                      </>
                    
                    }      
                  </>
                }
      
                {userType === 'appmanager' &&
                  <>
                    <div className={`menuItem`} onClick={toggleFeatureDashboardClick}>
                      <FaSwatchbook className={`icon`} />
                      <h2>Features</h2>
                    </div>
                    {showFeatureToggleDashboard && <FeatureDashboard user={user} onClose={toggleFeatureDashboardClick} />}
                  </>
                }
                
              </div>
            ) : (
              <div className={`menuItem`} onClick={(e) => logOut(e)}>
                <FaBug className={`icon`} />
                <h2>Profile error</h2>
                <p>Something went wrong with accessing your profile. Don&lsquo;t worry we&lsquo;ve sent an error report to the App Admin on you behalf already, so it should be fixed soon :)</p>
              </div>
            )}
          </>
    
          {showSettings && <Settings user={user} students={students} onClose={toggleSettingsClick} />}
          {showUserInfo && <UserInfo onClose={toggleUserInfoClick} />}

          <ConnectionMonitor />  
          <ToastContainer />
    
          <footer className={profileStyles.footer}>
            {user && <Footer onSettingsOpen={toggleSettingsClick} onUserInfoOpen={toggleUserInfoClick} />}
          </footer>
    
        </>
      } 
    </>
  )
}

export default Profile;