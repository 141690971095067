import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.scss';
import App from './App';
import ErrorBoundary from './ErrorBoundary';

/*
* The ErrorBoundary is inside the Provider
* so that any components within the boundary
* have access to the Redux store
*/

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
);
