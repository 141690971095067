import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaAngleRight } from 'react-icons/fa';

import { format } from 'date-fns';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from '../../styles/Register.module.scss';

const StudentRegister = ({ handleInputChange, handleNext, studentInfo }) => {

  const today = new Date();
  const fiveYearsAgo = new Date(today.setFullYear(today.getFullYear() - 5)); // students must be at least 5 to join

  const [dobDate, setDobDate] = useState(fiveYearsAgo);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy');
  }

  /*
  * NOTE:
  * -----
  * Users need to click away from the date picker popup in order to progress; it
  * annoys the shit out of me but there's currently no way around it - even though
  * the documentation seems to think you can use the open attribute or some ref
  * tricks - it doesn't work :/
  */

  useEffect(() => {
    const formattedDate = formatDate(dobDate);
    handleInputChange('dateOfBirth', formattedDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dobDate]);

  return (
    <>
      <h1 className={`shadow`}>Student Basic Information</h1>
      <div className={styles.formComponents}>
        <div className={styles.studentInfoContainer}>
          <label>
            Student First Name
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={studentInfo.firstname.value}
              onChange={(e) => handleInputChange('firstname', e.target.value)}
              style={{ borderColor: studentInfo.firstname.isValid ? 'green' : 'red' }}
              required
            />
          </label>
          <div className={styles.errorLabel}>{studentInfo.firstname.errorMessage}</div>
        </div>
        <div className={styles.studentInfoContainer}>
          <label>
            Student Surname
            <input
              type="text"
              id="surname"
              name="surname"
              value={studentInfo.surname.value}
              onChange={(e) => handleInputChange('surname', e.target.value)}
              style={{ borderColor: studentInfo.surname.isValid ? 'green' : 'red' }}
              required
            />
          </label>
          <div className={styles.errorLabel}>{studentInfo.surname.errorMessage}</div>
        </div>
        <div className={styles.studentInfoContainer}>
          <label>
            Date of Birth (dd/mm/yyyy)<br />
            <DatePicker
              selected={dobDate}
              onChange={(date) => setDobDate(date)}
            />
          </label>
          <div className={styles.errorLabel}>{studentInfo.dateOfBirth.errorMessage}</div>
        </div>
        <div className={styles.studentInfoContainer}>
          <label>
            Please add any previous<br />martial arts experience.<br />Describe style(s) and<br />grade(s) achieved.
            <textarea rows="4" cols="50"
              id="previousExperience"
              name="previousExperience"
              value={studentInfo.previousExperience.value}
              onChange={(e) => handleInputChange('previousExperience', e.target.value)}
              style={{ borderColor: 'green'}}
            />
          </label>
          <div className={styles.errorLabel}></div>
        </div>
      </div>
      <button type="button" onClick={handleNext} className={styles.nextButton}>Next <FaAngleRight /></button>
    </>
  );
};

StudentRegister.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  studentInfo: PropTypes.shape({
    firstname: PropTypes.shape({
      value: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired,
    surname: PropTypes.shape({
      value: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired,
    dateOfBirth: PropTypes.shape({
      value: PropTypes.string.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired,
    previousExperience: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default StudentRegister;