import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { auth } from '../../firebase/firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { setError, clearError } from '../../redux/errorSlice';

import { setIsLoading } from '../../redux/loadingSlice';

import { FaUserNinja, FaLock, FaTimes } from 'react-icons/fa';
import loginStyles from '../../styles/Login.module.scss';

// eslint-disable-next-line react/prop-types
const Login = ({ serverIsConnected }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isError = useSelector((state) => state.error.isError);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const isRegistering = useSelector((state) => state.registering.isRegistering);

  const loginWithUsernameAndPassword = async (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("./profile");
      dispatch(setError(''));
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        dispatch(setError('Invalid email and/or password'));
      } else if (error.code === 'auth/user-not-found') {
        dispatch(setError('User not found'));
      } else {
        dispatch(setError('Invalid email and/or password'));
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  const reloadPage = async (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));
    try {
      navigate(0); // current page
      dispatch(clearError());
    } catch (error) {
      dispatch(setError('Cannot connect'));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const setEmailValue = (emailValue) => {
    if ((emailValue).trim().length > 0) {
      setEmail(emailValue);
    } else {
      dispatch(clearError());
    }
  }

  const setPasswordValue = (passwordValue) => {
    if ((passwordValue).trim().length > 0) {
      setPassword(passwordValue);
    } else {
      dispatch(clearError());
    }
  }

  useEffect(() => {
    if (isError.length > 0) {
      console.error(isError);
    }
  }, [isError]);
  
  return(
    <>
      {isRegistering ? (
        <></>
      ) : (
        <form>
          <div className={loginStyles.formComponents}>
              
          {serverIsConnected ? (

            <>
              <div className={loginStyles.inputBox}>
                <div>
                  <label htmlFor="userEmail" className={loginStyles.inputLabel}>
                    <FaUserNinja className={loginStyles.icon} />
                    <input
                      type="email"
                      id="userEmail"
                      placeholder="name@example.com"
                      value={email}
                      onChange={ (e) => setEmailValue(e.target.value) }
                      autoComplete="email"
                    />
                  </label>
                </div>

                <div>
                  <label htmlFor="userPw" className={loginStyles.inputLabel}>
                    <FaLock className={loginStyles.icon} />
                    <input
                      type="password"
                      id="userPw"
                      placeholder="Password"
                      value={password}
                      onChange={ (e) => setPasswordValue(e.target.value) }
                      autoComplete="current-password"
                    />
                  </label>
                </div>
              </div>

              <div className={loginStyles.loginButtonContainer}>
                <button type="submit" className={loginStyles.authButton} onClick={(e) => loginWithUsernameAndPassword(e)}>
                  <span className="shadow">Login</span>
                </button>
              </div>
            </>
                
          ) : (

            <>
              <div className={loginStyles.offlineBox}>
                <div>
                  <label htmlFor="userReload">
                    Server is offline. &nbsp;
                    <button
                      id="userReload"
                      className={loginStyles.authButton}
                      onClick={reloadPage}
                    >
                      <span className="shadow">Reload</span>
                    </button>
                  </label>
                </div>
              </div>
            </>

          )}

          </div>
        </form>

)}
      <div className={`${isError.length > 0 ? loginStyles.loginErrorContainer : loginStyles.loginErrorPlaceholder}`}>
        {(isError.length > 0) ? (
          <span><FaTimes className={loginStyles.icon} />{isError}</span>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default Login;