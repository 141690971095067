import React from 'react';
import styles from '../../styles/settingsMenu.module.scss';

// eslint-disable-next-line react/prop-types
const CardThumbnail = ({ imageUrl, onClick, isSelected }) => {
    const handleClick = () => {
      onClick(imageUrl);
    };

    return (
      <div className={`${styles.cardItem} ${isSelected ? styles.selected : styles.unselected}`} onClick={handleClick}>
        <img className={styles.cardSelectThumb} src={imageUrl} alt="Card Thumbnail" />
      </div>
    );
};

export default CardThumbnail;