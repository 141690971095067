import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MessageTable from './MessageTable';

import useApiCall from '../../functions/useApiCall';
import { setIsLoading } from '../../redux/loadingSlice';

import LoadingScreen from '../screen/Loading';

import styles from '../../styles/Profile.module.scss';

// eslint-disable-next-line react/prop-types
const SendMessage = ({ user, onSubmit, onClose }) => {
	// eslint-disable-next-line no-unused-vars
	const [unsentMessages, setUnsentMessages] = useState();
  // eslint-disable-next-line no-unused-vars
  const [unsentMessageCount, setUnsentMessageCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [sentMessageCount, setSentMessageCount] = useState(0);

  const { apiFetch } = useApiCall();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');

  const isLoading = useSelector((state) => state.loading.isLoading);

  const fetchUnsentMessages = async (userEmail, userUid) => {
    const action = 'unsentMessages';
    const sendableMessageData = { email: userEmail, token: userUid };
    setIsLoading(true);
    const result = await apiFetch(action, sendableMessageData);
    if (result && result.length > 0) {
      setUnsentMessages(result);
    }
  };

  const fetchRecentlySentMessages = async (userEmail, userUid) => {
    const action = 'recentlySentMessages';
    const sentMessageData = { email: userEmail, token: userUid };
    setIsLoading(true);
    const result = await apiFetch(action, sentMessageData);
    if (result && result.length > 0) {
      setSentMessageCount(result[0].recentlySent);
    }
  };

	const handleSend = (message) => {

		const messageData = {
      id: message.id,
			title: message.title,
			content: message.content
		};

		onSubmit(messageData);
	};

  useEffect(() => {
    setIsLoading(false);
    if (unsentMessages && unsentMessages.length > 0) {
      setUnsentMessageCount(unsentMessages.length);
    }
  }, [unsentMessages]);

  useEffect(() => {
    setIsLoading(false);
  }, [sentMessageCount]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    fetchUnsentMessages(user.email, user.uid);
    // eslint-disable-next-line react/prop-types
    fetchRecentlySentMessages(user.email, user.uid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

	return (
		<>
			<div className={styles.formFullWidthComponents}>
        <h1 className={`shadow`}>Send a Message</h1>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <></>
        )}
        <>
          <h2 className={`messageSentCount`}>[{unsentMessageCount}] unsent {(unsentMessageCount != 1) ? 'messages' : 'message'}, [{sentMessageCount}] recently sent {(sentMessageCount != 1) ? 'messages' : 'message'}</h2>
          {unsentMessages ? (
            <MessageTable unsentMessages={unsentMessages} handleSend={handleSend} />
          ) : (
            <p>You currently have no unsent messages. Add a message, then it will be shown here.</p>
          )}

          <button type="submit" className={styles.closeButton} onClick={onClose}>
            <span className="shadow">Close</span>
          </button>
        </>
			</div>
		</>
	);
};

export default SendMessage;