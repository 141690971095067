/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setUnreadMessages } from '../../redux/messageSlice';

import LoadingScreen from '../screen/Loading';
import { setIsLoading } from '../../redux/loadingSlice';

import useApiCall from '../../functions/useApiCall';

import { FaCheck } from 'react-icons/fa';
import styles from '../../styles/Inbox.module.scss';

const UserInboxMessage = ({ message, user }) => {
  const dispatch = useDispatch();

  const [messageIsRead, setMessageIsRead] = useState();

  const userInfo = useSelector((state) => state.auth.userInfo);

  const { apiFetch } = useApiCall();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');
  const isLoading = useSelector((state) => state.loading.isLoading);

  const markAsRead = async (messageId) => {
    // eslint-disable-next-line react/prop-types
    const readMessageData = { email: user.email, token: user.uid, userId: userInfo.id, messageId: messageId };
    const action = 'readMessage';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, readMessageData);
    if (response.affectedRows == 1 && response.serverStatus == 2) {
      setMessageIsRead(true);
    } else {
      setMessageIsRead(false);
    }
    dispatch(setIsLoading(false));
  };

  const fetchUnreadMessages = async (userEmail, userUid) => {
    // eslint-disable-next-line react/prop-types
    const unreadMessageData = { email: userEmail, token: userUid };
    const action = 'unreadMessages';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, unreadMessageData);
    try {
      const unreadMessages = response;
      dispatch(setUnreadMessages(unreadMessages)); /* we need to set this to get the updated amount */  
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (messageIsRead) {
      fetchUnreadMessages(user.email, user.uid);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageIsRead]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <></>
      )}
      {message && (
        <>
          <div key={message.id} className={`${styles.slidyDiv} ${messageIsRead ? styles.slidyDivSelected : styles.slideLeft}`}>
            <div className={styles.messageHeading}>
              <h2 className={`shadow`}>{message.title}</h2>
              <h2 className={styles.sentDate}>{new Date(message.sent_date).toLocaleString()}</h2>
            </div>
            <p className={`shadow`}>
              <span className={styles.content}>{message.content}</span>
              <button onClick={() => markAsRead(message.id)} className={styles.markAsReadButton}>
                <FaCheck className={styles.icon} />
              </button>
            </p>                
          </div>
        </>
      )}
    </>
  );
};

export default UserInboxMessage;
