import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  students: [],
};

const studentInfoSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setStudents: (state, action) => {
      state.students = action.payload;
    },
    // eslint-disable-next-line no-unused-vars
    clearStudents: (state, action) => {
      state.students = [];
    },
    updateStudent: (state, action) => {
      const { studentId, student } = action.payload;
      const index = state.students.findIndex(student => student.id === studentId);
      if (index !== -1) {
        state.students[index] = { ...state.students[index], ...student };
      }
    },
  },
});

export const { setStudents, clearStudents, updateStudent } = studentInfoSlice.actions;
export default studentInfoSlice.reducer;