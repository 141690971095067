/* eslint-disable react/prop-types */
import React from 'react';

import Toggle from 'react-toggle';
//import 'react-toggle/style.css'; // NOTE: Already included in StudentContact; if that is removed uncomment this

const FeatureToggle = ({ feature, featureStates, handleInputChange, errorLabelClass }) => {
  return (
    <label key={feature}>
      <Toggle
        id={feature}
        name={feature}
        defaultChecked={featureStates[feature]}
        onChange={(e) => handleInputChange(feature, e.target.checked)}
      />
      <span className={`toggleLabelText`}>
        {feature.replace(/([A-Z])/g, ' $1')} {/* Format for display */}
      </span>
      <div className={errorLabelClass}>{featureStates[feature]}</div>
    </label>
  );
};

export default FeatureToggle;