import { createSlice } from '@reduxjs/toolkit';

/* NOTE: make sure these values are the same as the default db values for migration, and you update FeatureToggles */
const initialState = {};

export const featuresSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setFeatureStatus: (state, action) => {
      const { featureName, status } = action.payload;
      state[featureName] = status;
    },
  },
});

export const {
  setFeatureStatus,
} = featuresSlice.actions;
export default featuresSlice.reducer;