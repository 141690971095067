import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import LoadingScreen from '../../screen/Loading';
import { setIsLoading } from '../../../redux/loadingSlice';

import useApiCall from '../../../functions/useApiCall';

import FullScreenWrapper from '../../utils/FullscreenWrapper';

import { FaTimes } from 'react-icons/fa';
import styles from '../../../styles/Profile.module.scss';

import ActiveUsersChart from './ActiveUsersChart';
import DojoUsersChart from './DojoUsersChart';

// eslint-disable-next-line react/prop-types
const ReportOfUsers = ({ user, onClose }) => {
  const { apiFetch } = useApiCall();
  const dispatch = useDispatch();

  const [activeUserData, setActiveUserData] = useState();
  const [dojoUserData, setDojoUserData] = useState();

  const [error, setError] = useState('');

  const [nowDate, setNowDate] = useState();

  const isLoading = useSelector((state) => state.loading.isLoading);

  const fetchActiveUsersInformation = async (userEmail, userUid) => {
    const userData = { email: userEmail, token: userUid };
    const action = 'reporting/users/active';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, userData);
    try {
      setActiveUserData(response);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    dispatch(setIsLoading(false));
  };

  const fetchUsersPerLocationInformation = async (userEmail, userUid) => {
    const userData = { email: userEmail, token: userUid };
    const action = 'reporting/users/location';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, userData);
    try {
      setDojoUserData(response);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (user) {
      // eslint-disable-next-line react/prop-types
      const userEmail = user.email, userUid = user.uid;
      fetchActiveUsersInformation(userEmail, userUid);
      fetchUsersPerLocationInformation(userEmail, userUid);
      setNowDate(format(new Date(), "dd MMM yyyy HH:mm"));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullScreenWrapper>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className={styles.formComponents}>
          <h1 className={`shadow`}>Reporting Dashboard</h1>
          <div className={styles.chartContainer}>
            <div className={styles.chartHeading}>
              {nowDate &&
                <h2 className={`shadow`}>User Reports {nowDate.toLocaleString()}</h2>
              }
            </div>
            <div className={styles.chart}>
              {activeUserData &&
                <div className={`${styles.chartPadding} ${styles.chartLight}`}>
                  <ActiveUsersChart initialData={activeUserData} />
                </div>
              }
              {dojoUserData &&
                <div className={`${styles.chartPadding} ${styles.chartLight}`}>
                  <DojoUsersChart initialData={dojoUserData} />
                </div>
              }              
            </div>  
            <br />
            
            <button type="submit" className={styles.closeButton} onClick={onClose}>
              <span className="shadow">Close</span>
            </button>

          </div>

          <div className={`${error ? styles.errorContainer : styles.errorPlaceholder}`}>
            {error ? (
              <><FaTimes className={styles.icon} /><span className={styles.error}>{error}</span></>
            ) : (
              <></>
            )}
          </div>

        </div>
      )}
    </FullScreenWrapper>
  );
};

export default ReportOfUsers;