import React from 'react';
import PropTypes from 'prop-types';
import ThemeOption from '../utils/themeOption';
import { /*FaRunning, FaWalking,*/ FaShieldAlt, FaChild } from 'react-icons/fa';

import styles from '../../styles/Register.module.scss';

const UserTypeRegister = ({ handleUserTypeSelect }) => {
  return (
    <>
      <h1 className={`shadow`}>I am a</h1>
      <ul className={styles.userTypes}>
        {/*}
        <ThemeOption
          icon={FaRunning}
          name="Sensei"
          description="I am a teacher and head of the Dojo"
          theme="admin"
          onSelect={handleUserTypeSelect}
        />
        <ThemeOption
          icon={FaWalking}
          name="Senpai"
          description="I am a teacher and student of the Sensei"
          theme="powerUser"
          onSelect={handleUserTypeSelect}
        />
        */}
        <ThemeOption
          icon={FaChild}
          name="Student"
          description="I am a student"
          theme="student"
          onSelect={handleUserTypeSelect}
        />
        <ThemeOption
          icon={FaShieldAlt}
          name="Trusted Adult"
          description="I am a parent or legal guardian of a student, but not a student myself"
          theme="trustedAdult"
          onSelect={handleUserTypeSelect}
        />
      </ul>
      <br />
    </>
  );
};

UserTypeRegister.propTypes = {
  handleUserTypeSelect: PropTypes.node.isRequired,
};

export default UserTypeRegister;