import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import LoadingScreen from '../screen/Loading';
import FullScreenWrapper from '../utils/FullscreenWrapper';

import { FaTimes } from 'react-icons/fa';
import styles from '../../styles/Profile.module.scss';

// eslint-disable-next-line react/prop-types
const UserInfo = ({ onClose }) => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  //const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');

  const isLoading = useSelector((state) => state.loading.isLoading);

  return (
    <FullScreenWrapper>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <></>
      )}
        <div className={styles.formComponents}>
          <h1 className={`shadow`}>{userInfo.firstname} {userInfo.surname}</h1>
          <div className={styles.container}>

            <label htmlFor="mobileNumber" className={styles.inputLabel}>
              Mobile Number
              <input
                type="number"
                id="mobileNumber"
                placeholder="Mobile Number"
                value={userInfo.phone}
                readOnly
                style={{ borderColor: 'green' }}
                min="0"
              />
            </label>
            
            <button type="submit" className={styles.closeButton} onClick={onClose}>
              <span className="shadow">Close</span>
            </button>

          </div>

          <div className={`${error ? styles.errorContainer : styles.errorPlaceholder}`}>
            {error ? (
              <><FaTimes className={styles.icon} /><span className={styles.error}>{error}</span></>
            ) : (
              <></>
            )}
          </div>

        </div>

    </FullScreenWrapper>
  );
};

export default UserInfo;