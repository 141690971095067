import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FullScreenWrapper from '../utils/FullscreenWrapper';

import UserInboxMessage from './UserInboxMessage';

import styles from '../../styles/Inbox.module.scss';

// eslint-disable-next-line react/prop-types
const UserInbox = ({ user, onClose }) => {

  // eslint-disable-next-line react/prop-types
  const unreadMessages = useSelector((state) => state.messages.messages) || [];
  const [messageCount, setMessageCount] = useState(unreadMessages.length) || 0;

  useEffect(() => {
    const updatedMessageCount = unreadMessages.length || 0;
    setMessageCount(updatedMessageCount);
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [unreadMessages]);
 
  return (
    <FullScreenWrapper>
        <h1 className={`shadow`}>New Messages</h1>
        <div className={styles.inboxContainer}>
          <div><h2>You have {(messageCount < 1) ? 'No' : messageCount} new {(messageCount === 1 ? 'Message' : 'Messages')}</h2></div>
          <div className={styles.inboxContainer}>
          {(messageCount > 0) ? (
            // eslint-disable-next-line react/prop-types
            unreadMessages.map((message) => (
                <UserInboxMessage key={message.id} message={message} user={user} />
            ))
          ) : (
            <>{/* TODO: Maybe add some kind of cute emoji & accompanying message here */}</>
          )}
          </div>
        </div>
        <button type="submit" className={styles.button} onClick={onClose}>
          <span className="shadow">Close</span>
        </button>
    </FullScreenWrapper>
  );
};

export default UserInbox;