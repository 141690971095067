import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
};

const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setUnreadMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const { setUnreadMessages } = messageSlice.actions;
export default messageSlice.reducer;