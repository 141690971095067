// chartBaseOptions.js
const chartBaseOptions = {
  chart: {
    type: 'area',
    height: 350,
    width: 600,
    zoom: {
      enabled: false,
    },
  },
  toolbar: {
    show: false,
  },
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: 'smooth',
  },
  title: {
    text: 'Registrations - Monthly Active Users',
  },
  xaxis: {
    labels: {
      style: {
        colors: ['#ffffff'],
        fontSize: 'small',
      },
    },
  },
  yaxis: {
    opposite: false,
    labels: {
      style: {
        color: ['#ffffff'],
        fontSize: 'small',
      },
      rotate: 0,
    },
  },
  tooltip: {
    style: {
      fontSize: 'medium',
      color: '#ffffff',
    },
  },
  legend: {
    horizontalAlign: 'left',
    labels: {
      style: {
        colors: ['#ffffff'],
      },
    },
  },
  grid: {
    padding: {
      top: 24,
      right: 34,
      bottom: 14,
      left: 4,
    },
  },
  colors: ['#066543', '#faa543'],
  theme: {
    mode: 'light', /* 'dark', */
    /* palette: 'palette10', */
    monochrome: {
      enabled: false,
    },
  },
};

export default chartBaseOptions;
