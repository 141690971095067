import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cardId: 1,
  cardName: "Default",
  cardDescription: "This is the default card available to all students"
};

const cardIdSlice = createSlice({
  name: 'cardId',
  initialState,
  reducers: {
    setCardDescription: (state, action) => {
      state.cardInfo = {
        ...state.cardInfo,
        ...action.payload
      }
    },
  },
});

export const { setCardDescription } = cardIdSlice.actions;
export default cardIdSlice.reducer;