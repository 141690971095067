import { createSlice } from '@reduxjs/toolkit';
import { auth } from '../firebase/firebase';

const defaultTheme = 'signUp';
const defaultLocation = 'None chosen yet';

/*
* Going to assume most users are trusted adults with 1+ minor students
* 
* DB RULE: every student MUST ALSO BE a user
* (but a trusted adult user, for example, won't be a student)
*
* DB RULE: if user is student, user's student_id will be NOT NULL
* DB RULE: if user is not student, student info will be an empty object
*/

const initialState = {
  isAuthenticated: false,
  user: null,
  theme: defaultTheme,
  userName: '',
  userSurname: '',
  userPhone: '',
  userLocation: defaultLocation,  
  userInfo: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserSurname: (state, action) => {
      state.userSurname = action.payload;
    },
    setUserPhone: (state, action) => {
      state.userPhone = action.payload;
    },
    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    clearUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.theme = defaultTheme;
      state.userName = '';
      state.userSurname = '';
      state.userPhone = '';
      state.userLocation = defaultLocation;
      state.userInfo = null;
    }
  },
});

export const { setUser, setTheme, setUserLocation, setUserName, setUserSurname, setUserPhone, setUserInfo, clearUser } = authSlice.actions;

export const listenToAuthChanges = () => (dispatch) => {
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      dispatch(setUser({
        uid: user.uid,
        email: user.email,
        token: user.accessToken
      }));
    } else {
      dispatch(clearUser());
    }
  });
};

export default authSlice.reducer;