/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import LoadingScreen from '../screen/Loading';
import { setIsLoading } from '../../redux/loadingSlice';

import { FaIdBadge } from 'react-icons/fa';

const IdCardButton = ({ student, setCurrentStudent, toggleStudentIdClick }) => {

  const isLoading = useSelector((state) => state.loading.isLoading);

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, [student]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <></>
      )}
      <div key={student.id} className={`menuItem`} onClick={(e) => {
        e.preventDefault();
        setCurrentStudent(student);
        toggleStudentIdClick();
      }}>
        <FaIdBadge className={`icon`} />
        <h2 className={`shadow`}>
          {student.firstname} {student.firstname && ((student.firstname).endsWith("s")) ? (
            <>&rsquo;</>
          ) : (
            <>&rsquo;s</>
          )}    
          <br />ID Card
        </h2>
      </div>
    </>
  );
};

export default IdCardButton;