import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearUser } from './redux/authSlice';

import LoadingScreen from './components/screen/Loading';
import ZoomControls from './components/utils/ZoomControls';

import Login from './components/signIn/Login';
import NewUser from './firebase/newUser';
import Profile from './components/dashboard/Profile';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import SignUpCard from './components/dashboard/SignUpCard';
import RegisterCard from './components/dashboard/RegisterCard';
import GenericWelcomeCard from './components/dashboard/WelcomeCard';

import logo from './styles/images/logo_280.png';
import styles from './styles/App.module.scss';

import Register from './components/register/Register';
import FinishedRegistration from './components/register/FinishedRegistration';

import { setIsLoading } from './redux/loadingSlice';
import { setFeatureStatus } from './redux/featuresSlice';

import useApiCall from './functions/useApiCall';

function App() {
  const dispatch = useDispatch();

  const isError = useSelector((state) => state.error.isError);

  const isLoading = useSelector((state) => state.loading.isLoading);
  const [serverIsConnected, setIsServerConnected] = useState();

  const theme = useSelector((state) => state.auth.theme);
  /* ...BUUUUT we need to set this as we've got some css entanglement */
  const [themeBackground, setThemeBackground] = useState();
  useEffect(() => {
    setThemeBackground(styles[theme]);
  }, [theme]);

  const server = process.env.REACT_APP_PATH || ''; // also used in useApiCall, CardGallery, package.json, and manifest.json
  const serverRoute = `/${server}`;
  const { apiGet, apiFetch } = useApiCall();

  /* NOTE: These are the feature toggles - update these when updating features migration */
  const isTryItFirstFeatureEnabled = useSelector((state) => state.feature.tryItFirstFeature);
  const isRegisterForClassFeatureEnabled = useSelector((state) => state.feature.registerForClassFeature);
  const featuresToCheck = ['tryItFirstFeature', 'registerForClassFeature'];
  const [noPublicFeaturesEnabled, setNoPublicFeaturesEnabled] = useState(false);

  const checkServerIsOnline = async () => {
    const action = 'ping';
    dispatch(setIsLoading(true));
    const result = await apiGet(action);

    try {
      if (result === "OK") {
        setIsServerConnected(true);
      } else {
        console.debug(result);
        setIsServerConnected(false);
        console.error(`Server found but is offline`);
      }
    } catch (error) {
      console.error(`Server not found`);
    }
    dispatch(setIsLoading(false));
  };

  const fetchPublicFeature = async (featureName) => {
    const featureData = { feature: featureName };
    const action = 'publicFeature';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, featureData);
    if (response && response[0]) {
      const isFeatureEnabled = (response[0].isActive) ? true : false;
      dispatch(setFeatureStatus({ featureName, status: isFeatureEnabled }));
    } else {
      console.debug(`Features not found, using defaults`);
    }
    dispatch(setIsLoading(false));
  };

  const fetchAllPublicFeatures = async () => {
    dispatch(setIsLoading(true));
    const publicData = { token: `public` };
    const action = 'allPublicFeatures';
    const response = await apiFetch(action, publicData);
    
    if (response && response.length > 0) {
      setNoPublicFeaturesEnabled(false);
    } else {
      console.debug(`No public features found, using defaults`);
      setNoPublicFeaturesEnabled(true);
    }
    
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (serverIsConnected) {
      dispatch(clearUser());
      featuresToCheck.forEach((feature) => {
        fetchPublicFeature(feature);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverIsConnected]);

  useEffect(() => {
    if (isError.length > 0) {
      console.error(isError);
    }
  }, [isError]);

  useEffect(() => {
    checkServerIsOnline();
    fetchAllPublicFeatures();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading  && <LoadingScreen />}
      <Router basename={serverRoute}>
        <div className={`${styles.container} ${themeBackground}`}>
          <>
            <header className={styles.header}>
            <ZoomControls />
              <img className="shadow" src={logo} alt="JKS North Shore" />
            </header>
            <main className={styles.main}>
              <Routes>
                <Route index element={
                  <div>
                    <Login serverIsConnected={serverIsConnected} />
                    <div>
                      {noPublicFeaturesEnabled ? (
                        <GenericWelcomeCard />
                      ) : (
                        <>
                          {isTryItFirstFeatureEnabled && <SignUpCard />}
                          {isRegisterForClassFeatureEnabled && <RegisterCard />}
                        </>
                      )}                      
                    </div>
                  </div>
                }></Route>
                <Route path="/signUp" element={<><NewUser /></>} />
                <Route path="/profile" element={<><Profile /></>} />

                <Route path="/register" element={<Register />} />

                <Route path="/nextSteps" element={<FinishedRegistration />} />
              </Routes>
            </main>
          </>
        </div>
      </Router>
      </>
  );
}

export default App