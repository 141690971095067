import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsRegistering } from '../../redux/registeringSlice'; 
import { useNavigate } from 'react-router-dom';

import { setSignUpStep } from '../../redux/signUpSlice';

// TODO: Add registered student info to db for activation on successful verification

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import UserTypeRegister from './UserTypeRegister';
import StudentRegister from './StudentRegister';
import StudentContact from './StudentContact';
import EmergencyRegister from './EmergencyRegister';
import Equipment from './Equipment';
import Accept from './Accept';

import isValidEmail from '../../validation/emailValidator';
import isValidLocalPhone from '../../validation/phoneNumberValidator';
import isValidDob from '../../validation/dateOfBirthValidator';
import isValidNameSurname from '../../validation/nameSurnameValidator';

const Register = () => {
  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.signUp.step);
  const navigate = useNavigate();

  const [studentInfo, setStudentInfoLocal] = useState({
    firstname: { value: '', isValid: true, errorMessage: '' },
    surname: { value: '', isValid: true, errorMessage: '' },
    dateOfBirth: { value: '', isValid: true, errorMessage: '' }, //dd-mm-yyyy
    previousExperience: { value: '', isValid: true, errorMessage: '' },
    email: { value: '', isValid: true, errorMessage: '' },
    phone: { value: '', isValid: true, errorMessage: '' },
    emergency_firstname: { value: '', isValid: true, errorMessage: '' },
    emergency_surname: { value: '', isValid: true, errorMessage: '' },
    emergency_email: { value: '', isValid: true, errorMessage: '' },
    emergency_phone: { value: '', isValid: true, errorMessage: '' },
    requireGi: { value: true, isValid: true, errorMessage: '' },
    giHeight: { value: 0, isValid: true, errorMessage: '' },
    agreeToTsAndCs: { value: false, isValid: true, errorMessage: '' },
  });

  const handleUserTypeSelect = () => {
    dispatch(setSignUpStep(1));
  };

  const validateName = (nameValue) => {
    if (isValidNameSurname(nameValue)) {
      return {
        isValid: true,
        errorMessage: ''
      };
    } else {
      return {
        isValid: false,
        errorMessage: 'Please enter a valid name/surname'
      };
    }
  }

  const validateDateOfBirth = (dobValue) => {
    if (isValidDob(dobValue)) {
      return {
        isValid: true,
        errorMessage: ''
      };
    } else {
      return {
        isValid: false,
        errorMessage: 'Please enter a valid date of birth'
      };
    }
  };

  const validateEmail = (emailValue) => {
    if (isValidEmail(emailValue)) {
      return {
        isValid: true,
        errorMessage: ''
      };
    } else {
      return {
        isValid: false,
        errorMessage: 'Please enter a valid email address'
      };
    }
  }

  const validatePhoneNumber = (phoneValue) => {
    if (isValidLocalPhone(phoneValue)) {
      return {
        isValid: true,
        errorMessage: ''
      };
    } else {
      return {
        isValid: false,
        errorMessage: 'Please enter a valid local phone number'
      };
    }
  };

  const handleInputChange = (name, value) => {
    if (name === 'email') {
      const { isValid, errorMessage } = validateEmail(value);
    
      setStudentInfoLocal(prevState => ({
      ...prevState,
      email: {
        ...prevState.email,
        value: value,
        isValid: isValid,
        errorMessage: errorMessage
      }
    }));
    } else if (name === 'hasNoEmail') {
      const { isValid } = validateName(value);
      
      setStudentInfoLocal(prevState => ({
        ...prevState,
        email: {
          value: 'Use emergency contact',
          isValid: isValid,
          errorMessage: ''
        }
      }));
    } else if (name === 'firstname' || name === 'surname') {
      const { isValid, errorMessage } = validateName(value);
      
      setStudentInfoLocal(prevState => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value,
          isValid: isValid,
          errorMessage: errorMessage
        }
      }));
    } else if (name === 'dateOfBirth') {
      const { isValid, errorMessage } = validateDateOfBirth(value);
      
      setStudentInfoLocal(prevState => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value,
          isValid: isValid,
          errorMessage: errorMessage
        }
      }));
    } else if (name === 'phone') {
      const { isValid, errorMessage } = validatePhoneNumber(value);
      
      setStudentInfoLocal(prevState => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value,
          isValid: isValid,
          errorMessage: errorMessage
        }
      }));
    } else if (name === 'hasNoPhone') {
      const { isValid } = validateName(value);
      
      setStudentInfoLocal(prevState => ({
        ...prevState,
        phone: {
          value: 'Use emergency contact',
          isValid: isValid,
          errorMessage: ''
        }
      }));
    } else {
      setStudentInfoLocal(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleNext = () => {
    dispatch(setSignUpStep(selectedTab + 1));
  };

  const handleFormComplete = () => {
    //dispatch(setStudentInfo(studentInfo));
    navigate('/nextSteps');
    dispatch(setIsRegistering(false));
  };

  return (
    <form>
      <Tabs selectedIndex={selectedTab} onSelect={index => dispatch(setSignUpStep(index))}>
        <TabList>
        <Tab>User</Tab>
        <Tab>Student</Tab>
        <Tab>Contact</Tab>
        <Tab>Emergency</Tab>
        <Tab>Equipment</Tab>
        <Tab>Accept</Tab>
      </TabList>
        <TabPanel>
          <UserTypeRegister handleUserTypeSelect={handleUserTypeSelect} />
        </TabPanel>
        <TabPanel>
          <StudentRegister handleInputChange={handleInputChange} handleNext={handleNext} studentInfo={studentInfo} />
        </TabPanel>
        <TabPanel>
          <StudentContact handleInputChange={handleInputChange} handleNext={handleNext} studentInfo={studentInfo} />
        </TabPanel>
        <TabPanel>
          <EmergencyRegister handleInputChange={handleInputChange} handleNext={handleNext} studentInfo={studentInfo} />
        </TabPanel>
        <TabPanel>
        <Equipment handleInputChange={handleInputChange} handleNext={handleNext} studentInfo={studentInfo} />
        </TabPanel>
        <TabPanel>
          <Accept handleInputChange={handleInputChange} handleFormComplete={handleFormComplete} />
        </TabPanel>
      </Tabs>
    </form>
  );
};

export default Register;
