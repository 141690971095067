/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsLoading } from '../../redux/loadingSlice';

import useApiCall from '../../functions/useApiCall';

//import { messaging, getToken, onMessage } from '../../firebase/firebase';

import { updateStudent } from '../../redux/studentInfoSlice';

import LoadingScreen from '../screen/Loading';
import FullScreenWrapper from '../utils/FullscreenWrapper';

import CardGallery from '../utils/CardGallery';

import { FaTimes } from 'react-icons/fa';
import styles from '../../styles/Profile.module.scss';

const Settings = ({ user, students, onClose }) => {
  
  // student card settings
  const isStudentIdFeatureEnabled = useSelector((state) => state.feature.studentIdFeature);
  const studentInfo = useSelector((state) => state.students);
  const [showCardIdSavedGif, setShowCardIdSavedGif] = useState();
  
  const dispatch = useDispatch();

  const { apiFetch } = useApiCall();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');

  const isLoading = useSelector((state) => state.loading.isLoading);
/*
  const [token, setToken] = useState('');
  const [permissionGranted, setPermissionGranted] = useState(false);

  const requestPermission = async () => {
    console.log('Requesting notification permission...');
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        setIsPushNotificationSupported(true);
        setPermissionGranted(true);
        const currentToken = await getToken(messaging, { vapidKey: vapidPublicKey });
        if (currentToken) {
          console.log('FCM Token:', currentToken);
          setToken(currentToken);
          // Send the token to your server to store it
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      } else {
        console.log('Notification permission denied.');
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
    }
  };

  // TODO: Get this right for admin so that they can generate push notifications
  const sendNotification = () => {
    fetch(`http://${host}:${port}/${db}/jks/send-notification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: token,
        title: 'Test Notification',
        body: 'This is a test notification',
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Notification sent successfully:', data);
    })
    .catch(error => {
      console.error('Error sending notification:', error);
    });
  };

  useEffect(() => {
    if (permissionGranted) {
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // Customize notification here
        new Notification(payload.notification.title, {
          body: payload.notification.body,
        });
      });
    }
  }, [permissionGranted]);
  */

  const handleCardSelect = (student, newCardId) => {
    console.error(`Card ID: [${newCardId}]`);
    const cardId = Number(newCardId);
    if (isNaN(cardId)) {
        console.error(`Invalid card ID: [${newCardId}]`);
        return;
    }
    console.log(`Student id: [${student.id}]:`);
    console.log(`New card ID: [${cardId}]`);
    saveStudentCard(user.email, user.uid, student.id, newCardId);
  };

  const saveStudentCard = (userEmail, userUid, studentId, studentCardId) => {
    // eslint-disable-next-line react/prop-types
    const settingsCardData = { email: userEmail, token: userUid, studentId: studentId, cardId: studentCardId };
    const action = 'studentCard';
    dispatch(setIsLoading(true));
    const response = apiFetch(action, settingsCardData);
    try {
      if (response.ok) {
        const updatedStudentInfo = { ...studentInfo, cardId: studentCardId };
        dispatch(updateStudent(updatedStudentInfo));

        setShowCardIdSavedGif(true);
        setTimeout(() => {
          setShowCardIdSavedGif(false);
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    setShowCardIdSavedGif(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullScreenWrapper>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <></>
      )}
      <>
        <div className={styles.formComponents}>
          <h1 className={`shadow`}>Settings</h1>
          <div className={styles.container}>

      {/*
            {(isPushNotificationSupported) ? (
              <div className={`menuItem`}>
                {!(permissionGranted) && (
                  <button onClick={requestPermission}>Enable Notifications</button>
                )}
                {permissionGranted && (
                  <button onClick={sendNotification}>Send Test Notification</button>
                )}
              </div>
            ) : (
              <></>
            )}   
      */}

            {(isStudentIdFeatureEnabled && students && students.length > 0) ? (
              students.map((student) => (
                (student && Object.keys(student).length > 0) ? (
                  <div key={student.id}>
                    <h2>{student.firstname}&apos;s Settings</h2>
                    <CardGallery key={student.id} student={student} onCardSelect={(selectedCardId) => handleCardSelect(student, selectedCardId)} />
                    <p id={`cardIdStatusGif`} className={`status ${showCardIdSavedGif ? 'bounce-in' : 'hidden'}`}> Saved 💾 </p>
                  </div>
                ) : (
                  <></>
                )
              ))
            ) : (
              <></>
            )} 
          

          <button type="submit" className={styles.closeButton} onClick={onClose}>
              <span className="shadow">Close</span>
            </button>

            <div className={`${error ? styles.errorContainer : styles.errorPlaceholder}`}>
              {error ? (
                <><FaTimes className={styles.icon} /><span className={styles.error}>{error}</span></>
              ) : (
                <></>
              )}
            </div>

          </div>
        </div>
      </>
    </FullScreenWrapper>
  );
};

export default Settings;