// external functionalities are qualified with " not '
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAIKoxNBIgCWO56wD4NSnleaviGWRLaX1Q",
  authDomain: "sakura-jks.firebaseapp.com",
  projectId: "sakura-jks",
  storageBucket: "sakura-jks.appspot.com",
  messagingSenderId: "395068843164",
  appId: "1:395068843164:web:c1fec01eb1599cc868f959"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// const messaging = getMessaging(app);

export { auth /*, messaging, getToken, onMessage */ };