import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/IconMessage.module.scss';

const ThemeOption = ({ icon: Icon, name, description, theme, onSelect }) => {

  return (
    <li className={styles.iconMessage} onClick={() => onSelect(theme)}>
      <Icon className={styles.icon} />
      <div>
        <h2>{name}</h2>
        <p className={`shadow`}>{description}</p>
      </div>
    </li>
  );
};

ThemeOption.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  theme: PropTypes.node.isRequired,
  onSelect: PropTypes.node.isRequired,
};

export default ThemeOption;