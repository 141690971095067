import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleRight } from 'react-icons/fa';

import Toggle from 'react-toggle'
import 'react-toggle/style.css';

import styles from '../../styles/Register.module.scss';

const Accept = ({ handleInputChange, handleFormComplete }) => {

  return (
    <>
      <h1 className={`shadow`}>Complete Sign Up</h1>
      <div className={styles.formFullWidthComponents}>
        <h2 className={styles.subHeading}>Fees</h2>
        <p>The membership fee is raised annually and applies to all students.
        The training fee is raised quarterly (school term) and is due at the beginning of each quarter.
        Please see <a href="https://shotokan.net.nz/fees/">fees</a> for a list of updated fees.</p>
        {/*Membership to JKS Japan provides the student with a ‘Passport Book’ which records lifelong Karate progress and is required to be able to attend JKS national / international events, compete in JKS World Cup events and is a record of significant events, grading and qualification test achievements.*/}
        {/*Training fees are set by each JKS dojo and entitles the student to attend each class applicable to level or by invitation.
        An active paid training member in one dojo allows any student to train at other JKS NZ dojo’s for short periods free of charge. This is great news if you are travelling and want to keep your training program uninterrupted.
        In most cases dojo's continue with classes through the holiday period to allow for continuous training for students' benefit.     
        Training for ages 5-7 are 2 x 45 min classes weekly (Little Tigers), and for ages 8+ are 2-3 60 min classes weekly, with senior students training an additional 60 min per class.
        Optional 120 min weekend classes are available for senior students.
        Competition & invitation training over weekends incur an additional fee.

        <table>
          <tr><td colSpan={2}>Brownsbay & Orewa Dojo</td></tr>
          <tr><td colSpan={2}>Ages 5-7 (Little Tigers)</td></tr>
          <tr><td>Number of family members</td><td>Price per term: 2 classes weekly</td></tr>
          <tr><td>1</td><td>220.00 NZD</td></tr>
          <tr><td>2</td><td>400.00 NZD</td></tr>
          <tr><td>3+</td><td>530.00 NZD</td></tr>
          <tr><td colSpan={2}>Ages 8+ (General & Seniors)</td></tr>
          <tr><td>Number of family members</td><td>Price per term: 2-3 classes weekly</td></tr>
          <tr><td>1</td><td>220.00 NZD</td></tr>
          <tr><td>2</td><td>400.00 NZD</td></tr>
          <tr><td>3+</td><td>530.00 NZD</td></tr>
          <tr><td>Takapuna Dojo</td></tr>
          <tr><td colSpan={3}>Ages 5-7 (Little Tigers)</td></tr>
          <tr><td>Number of family members</td><td>Price per term: 1 class weekly</td><td>Price per term: 2 classes weekly</td></tr>
          <tr><td>1</td><td>150.00 NZD</td><td>200.00 NZD</td></tr>
          <tr><td colSpan={3}>Ages 8+ (General & Seniors)</td></tr>
          <tr><td>Number of family members</td><td colSpan={2}>Price per term: 2-3 classes weekly</td></tr>
          <tr><td>1</td><td colSpan={2}>220.00 NZD</td></tr>
          <tr><td>2</td><td colSpan={2}>400.00 NZD</td></tr>
          <tr><td>3+</td><td colSpan={2}>530.00 NZD</td></tr>
        </table>*/}
        <h2 className={styles.subHeading}>Terms & Conditions</h2>
        <p>By accepting I agree in the section below I do hereby submit my digital signature in my application for registration at JKS North Shore Karate.</p>
        <p><ol>
          <li>
            I/We hereby acknowledge and accept the JKS North Shore Rules (<a href="https://shotokan.net.nz/jks-terms-conditions/">terms and conditions</a>).
          </li><li>
            I accept that Karate is a martial art, and as such, indemnify the JKS North Shore Karate Club and it’s instructors from any liability caused through injury or mishap that may occur during training and/or competition.
          </li><li>
            I endeavour to train with strong Karate spirit, to give my best effort, and to follow the dojo kune at all times.
          </li><li>
            The JKS North Shore Karate reserves full right of admission, and has the sole right and authority to remove any student(s) if deemed in the best interest of JKS North Shore Karate and its Dojos, either temporarily or permanently.
          </li><li>
            I accept that JKS North Shore Karate will, from time to time take pictures/video, of me/my child during classes or tournaments and hereby give permission for them to be used on social media, and in all other advertising/promotional media.
          </li>
        </ol></p>
        
        <div className={styles.acceptToggle}>
          <label>
            <Toggle
              id="agreeToTsAndCs"
              name="agreeToTsAndCs"
              defaultChecked={false}
              onChange={(e) => handleInputChange('agreeToTsAndCs', e.target.value)}
            />
            <span className={styles.toggleLabelText}>
              I agree with the Club Declaration
            </span>
          </label>
        </div>
        
          {/* TODO: add reCAPCHA */}

      </div>
      <button type="button" onClick={handleFormComplete} className={styles.nextButton}>Complete <FaAngleRight /></button>
    </>
  );
};

Accept.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleFormComplete: PropTypes.func.isRequired
};

export default Accept;