import React from 'react';
import styled from 'styled-components';

const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  transition: all 1.7s;
  z-index: 500;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: green;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darkgreen;
  }

  scrollbar-width: thin; /* FF thin scrollbar */
  scrollbar-color: green rgba(255, 255, 255, 0.2); /* FF thumb & track */
`; /* maybe want to make this into a module, hence in-line styling */

// eslint-disable-next-line react/prop-types
const FullScreenWrapper = ({ children }) => {
  return <FullScreen>{children}</FullScreen>;
};

export default FullScreenWrapper;