/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingScreen from '../screen/Loading';
import { setIsLoading } from '../../redux/loadingSlice';
import { setFeatureStatus } from '../../redux/featuresSlice';

import useApiCall from '../../functions/useApiCall';

import FeatureToggle from './FeatureToggle';

import styles from '../../styles/Profile.module.scss';

// eslint-disable-next-line react/prop-types
const FeatureToggles = ({ user, publicFeatures, onClose }) => {
	const dispatch = useDispatch();

	const { apiFetch } = useApiCall();

	// eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');
  const isLoading = useSelector((state) => state.loading.isLoading);

	/* NOTE: make sure these values are the same as the default db values for migration */
	// public features
	// logged-in features
  const privateFeatures = ['pushMessagingFeature', 'messagingFeature', 'homeDojoFeature', 'studentIdFeature', 'reportingFeature', 'qrScanFeature'];
  const featureStates = useSelector((state) => state.feature); 

	const setFeature = async (featureName, featureValue, userEmail, userUid) => {
    const featureData = { isActive: featureValue, feature: featureName, email: userEmail, token: userUid };
    const action = 'feature';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, featureData);
    try {
			if (response.affectedRows == 1 && response.serverStatus == 34 && response.changedRows == 1) {
        const isFeatureEnabled = (featureValue) ? true : false; /* not gonna hurt */
        dispatch(setFeatureStatus({ featureName, status: isFeatureEnabled }));
			} else {
				throw new Error('Unexpected response, setting could not be updated');				
			}
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

	const handleInputChange = (name, status) => {
    if (user && publicFeatures && privateFeatures) {
      dispatch(setFeatureStatus({ name, status }));
      // eslint-disable-next-line react/prop-types
      setFeature(name, status, user.email, user.uid);
    }
  };

	return (
		<>
			{isLoading ? (
				<LoadingScreen />
			) : (
				<></>
			)}
			<div className={styles.formFullWidthComponents}>
				<h1 className={`shadow`}>App-wide Feature Toggles</h1>

				<h2 className={`shadow`}>Public Feature Toggles</h2>
        {publicFeatures && publicFeatures.map((feature) => (
        <FeatureToggle
          key={feature}
          feature={feature}
          featureStates={featureStates}
          handleInputChange={handleInputChange}
          errorLabelClass={styles.errorLabel}
        />
      ))}

				<h2 className={`shadow`}>Logged-in user Feature Toggles</h2>
				{privateFeatures && privateFeatures.map((feature) => (
        <FeatureToggle
          key={feature}
          feature={feature}
          featureStates={featureStates}
          handleInputChange={handleInputChange}
          errorLabelClass={styles.errorLabel}
        />
      ))}

				<div className={styles.buttonContainer}>
					<button type="submit" className={styles.button} onClick={onClose}>
						<span className="shadow">Close</span>
					</button>
				</div>

			</div>
		</>
	);
};

export default FeatureToggles;
