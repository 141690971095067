import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleRight } from 'react-icons/fa';

import styles from '../../styles/Register.module.scss';

const EmergencyRegister = ({ handleInputChange, handleNext, studentInfo }) => {


  return (
    <>
      <h1 className={`shadow`}>Emergency Contact Information</h1>
      <div className={styles.formFullWidthComponents}>

        <label>
          Emergency Contact First Name
          <input
            type="text"
            id="firstname"
            name="firstname"
            value={studentInfo.emergency_firstname.value}
            onChange={(e) => handleInputChange('firstname', e.target.value)}
            style={{ borderColor: studentInfo.emergency_firstname.isValid ? 'green' : 'red' }}
            required
          />
        </label>
        <div className={styles.errorLabel}>{studentInfo.emergency_firstname.errorMessage}</div>

        <label>
          Emergency Contact Surname
          <input
            type="text"
            id="surname"
            name="surname"
            value={studentInfo.emergency_surname.value}
            onChange={(e) => handleInputChange('surname', e.target.value)}
            style={{ borderColor: studentInfo.emergency_surname.isValid ? 'green' : 'red' }}
            required
          />
        </label>
        <div className={styles.errorLabel}>{studentInfo.emergency_surname.errorMessage}</div>

        <label>
          Emergency Contact E-mail address
          <input
            type="text"
            id="email"
            name="email"
            value={studentInfo.emergency_email.value}
            onChange=
              {(e) => handleInputChange('email', e.target.value)}
              style={{ borderColor: studentInfo.emergency_email.isValid ? 'green' : 'red' }}
              required
          />
        </label>
        <div className={styles.errorLabel}>{studentInfo.email.errorMessage}</div>

        <label>
          Emergency Contact Phone number (Local to NZ)
          <input
            type="text"
            id="phone"
            name="phone"
            value={studentInfo.emergency_phone.value}
            onChange={(e) => handleInputChange('phone', e.target.value)}
            style={{ borderColor: studentInfo.emergency_phone.isValid ? 'green' : 'red' }}
            required
          />
        </label>
        <div className={styles.errorLabel}>{studentInfo.emergency_phone.errorMessage}</div>

      </div>

      <button type="button" onClick={handleNext} className={styles.nextButton}>Next <FaAngleRight /></button>
    </>
  );
};

EmergencyRegister.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  studentInfo: PropTypes.shape({
    email: PropTypes.shape({
      value: PropTypes.string.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired,
    emergency_firstname: PropTypes.shape({
      value: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired,
    emergency_surname: PropTypes.shape({
      value: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired,
    emergency_email: PropTypes.shape({
      value: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired
    }).isRequired,
    emergency_phone: PropTypes.shape({
      value: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default EmergencyRegister;