import { createSlice } from '@reduxjs/toolkit';

/* NOTE: make sure these values are the same as the default db values for migration */
const initialState = {
  // public settings

  // logged-in settings
  messagingMaxCharacters: 512,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingValue: (state, action) => {
      const { settingName, value } = action.payload;
      state[settingName] = value;
    },
  },
});

export const {
  setFeatureStatus,
} = settingsSlice.actions;
export default settingsSlice.reducer;