import React from 'react';
import ImageGridItem from './ImageGridItem';
import welcomeWave from '../../styles/images/welcome-wave_227.png';

const WelcomeCard = () => {
  return (
    <ImageGridItem
      to="./signup"
      imgSrc={welcomeWave}
      imgAlt="Welcome!"
      title="Kia Ora!"
      description="Welcome to the JKS North Shore App!"
      isWide={true}
    />
  );
};

export default WelcomeCard;
