import React from 'react';
import ImageGridItem from './ImageGridItem';
import learn from '../../styles/images/learn-karate_227.png';

const RegisterCard = () => {
  return (
    <ImageGridItem
      to="/register"
      imgSrc={learn}
      imgAlt="Learn Karate"
      title="Register"
      description="Ready to learn Karate? Register now to get your first two weeks for free!"
      isWide={true}
    />
  );
};

export default RegisterCard;