import React from "react";
import profileStyles from '../../styles/Profile.module.scss';

import { FaToolbox, FaMobile } from 'react-icons/fa';

// eslint-disable-next-line react/prop-types
const Footer = ({ onSettingsOpen, onUserInfoOpen }) => {
  return(
    <>
      <div className={profileStyles.bottomMenuContainer}>

        <div className={profileStyles.link} onClick={onSettingsOpen}>
          <FaToolbox className={profileStyles.icon} />
          Settings
        </div>

        <div className={profileStyles.link} onClick={onUserInfoOpen}>
          <FaMobile className={profileStyles.icon} />
          My Info
        </div>

      </div>
    </>
  )
}

export default Footer