import React, { useState } from 'react';

import styles from '../../styles/Profile.module.scss';

// NOTE: To run this form independently, please add a component arg onClose below (next to onSubmit) and uncomment the close button in the buttonContainer below
// eslint-disable-next-line react/prop-types
const CreateMessage = ({ onSubmit }) => {
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');
	const maxHeadingChars = 100;
	const maxBodyChars = 255;  

	const handleSubmit = (e) => {
		e.preventDefault();

		const newMessageData = {
			title,
			content
		};

		onSubmit(newMessageData);
		setTitle('');
		setContent('');
	};

	return (
		<>
			<div className={styles.formFullWidthComponents}>
				<h1 className={`shadow`}>Add a Message</h1>

				<label>
					New Message Title:<br />
					<input
						type="text"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						maxLength={100}
						required
					/>
					<p className={styles.messageLength}>{title.length}/{maxHeadingChars} characters</p>
				</label>

				<label>
					New Message Content:<br />
					<textarea
						value={content}
						onChange={(e) => setContent(e.target.value)}
						maxLength={255}
						required
					/>
					<p className={styles.messageLength}>{content.length}/{maxBodyChars} characters</p>
				</label>

				{/*
				// we don't need to show this here as this form is shown with SendMessage, which has a close button
				<button type="submit" className={styles.cancelButton} onClick={onClose}>
					<span className="shadow">Close</span>
				</button>
				*/}
				<button type="submit" className={styles.closeButton} onClick={handleSubmit}>
					<span className="shadow">Create</span>
				</button>

			</div>
		</>
	);
};

export default CreateMessage;
