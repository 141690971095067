import React from 'react';
import ImageGridItem from './ImageGridItem';
import tryIt from '../../styles/images/little-tigers_227.png';

const SignUpCard = () => {
  return (
    <ImageGridItem
      to="./signup"
      imgSrc={tryIt}
      imgAlt="Have a Go"
      title="Try it first"
      description="Try it first, for free! Sign up here to login to the app."
      isWide={true}
    />
  );
};

export default SignUpCard;
