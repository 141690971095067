import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRegistering: false,
};

const registeringSlice = createSlice({
  name: 'isRegistering',
  initialState,
  reducers: {
    setIsRegistering: (state, action) => {
      state.isRegistering = action.payload;
    },
  },
});

export const { setIsRegistering } = registeringSlice.actions;

export default registeringSlice.reducer;
