import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { Html5Qrcode } from 'html5-qrcode';

import LoadingScreen from '../screen/Loading';
import { setIsLoading } from '../../redux/loadingSlice';

import useApiCall from '../../functions/useApiCall';

import FullScreenWrapper from '../utils/FullscreenWrapper';

import { } from 'react-icons/fa';
import styles from '../../styles/Profile.module.scss';

// eslint-disable-next-line react/prop-types
const QRCodeScanner = ({ user, onClose }) => {
  const { apiFetch } = useApiCall();
  const dispatch = useDispatch();

  const scannerRef = useRef(null);
  const [scanWidth, setScanWidth] = useState();
  const [scanHeight, setScanHeight] = useState();
  const [isReadyToStart, setIsReadyToStart] = useState();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();

  const isLoading = useSelector((state) => state.loading.isLoading);

  const [dojos, setDojos] = useState([]);
  const [selectedDojo, setSelectedDojo] = useState({ id: "0", name: "Please select Dojo" });

  const [scannedUserMessage, setScannedUserMessage] = useState();
  const [scannedUserId, setScannedUserId] = useState();
  const [scannedUserHash, setScannedUserHash] = useState();
  const [scannedUserDate, setScannedUserDate] = useState();

  const sizeScanFrame = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    setScanWidth(screenWidth * 0.5);
    setScanHeight(screenHeight * 0.5);
  }

  const newSqlDate = () => { 
    return format(new Date(), "yyyy-MM-dd HH:mm:ss");
  }

  const formattedDate = (dateString) => {
    if (dateString) {
      try {
        const formattedDate = format(dateString, "yyyy-MM-dd HH:mm:ss");
        return formattedDate;
      } catch (error) {
        console.warn(`Couldn't parse date from QR code, defaulting to now`);
        return newSqlDate();
      }
    } else {
      console.warn(`Parsed information is not a date from QR code, defaulting to now`);
      return newSqlDate();
    }
  }

  const getDojos = async (userEmail, userUid) => {
    const dojoData = { email: userEmail, token: userUid };
    const action = 'allDojos';
    dispatch(setIsLoading(true));
    const response = await apiFetch(action, dojoData);
    try {
      const dojoResponse = response;
      const dojoList = dojoResponse.map(location => ({
        id: location.id,
        name: location.name,
    }));
      setDojos(dojoList);
    } catch (error) {
      console.error(error);
    }
    dispatch(setIsLoading(false));
  };

  const handleDojoChange = (event) => {
    const dojoId = event.target.value;
    const dojoName = event.target.options[event.target.selectedIndex].text;
    setSelectedDojo({ id: dojoId, name: dojoName });
  };


  const parseQrData = (decodedText) => {
    if (/(\S+\s+\S+\s+\S+)/.test(decodedText)) { // at least 2 spaces (i.e. at least 3 words)
      setScannedUserId(decodedText.split(" ")[0]);
      setScannedUserHash(decodedText.split(" ")[1]);
      setScannedUserDate(formattedDate(decodedText.split(" ")[2]));
    } else {
      const message = `QR code is invalid`;
      console.log(message);
      setScannedUserMessage(message);
    }
  }

  const activateQrScanner = () => {
    scannerRef.current
      .start(
        { facingMode: 'environment' },
        { fps: 1, qrbox: { width: 250, height: 250 } },
        (decodedText) => {
          parseQrData(decodedText);              
        },
        // eslint-disable-next-line no-unused-vars
        (_error) => {
          // An error here includes every time-out fps that something wasn't scanned, so be cautiious when enabling!
          //setError(error);
        }
      )
      .catch((error) => console.error(`QR Code Scanner Startup Error: `, error));
  }

  const startQrScanner = () => {    
    if (isReadyToStart) {
      setIsReadyToStart(false);
      activateQrScanner();
      setError('Please scan next Student ID QR Code');
    }
  };

  const stopQrScanner = async () => {
    if (scannerRef.current) {
      try {
        await scannerRef.current.stop();
      } catch (err) {
        console.warn('Error stopping the scanner:', err);
      }
    }
  };

  const handleClose = async () => {
    await stopQrScanner();
    onClose(); // parent component's close callback
  };

  const confirmStudentIdScan = async (qrName) => {  
    setScannedUserMessage(`${qrName} scanned succesfully`);
    setError(`Tap here to confirm attendance`);
  };

  const confirmStudent = async () => {
    // eslint-disable-next-line react/prop-types
    if (user && scannedUserId && scannedUserHash && scannedUserDate) {
      // eslint-disable-next-line react/prop-types
      const studentIdQrData = { email: user.email, token: user.uid, hash: scannedUserHash, identifier: scannedUserId, locationId: selectedDojo.id, date: scannedUserDate };
      const action = 'studentIdScanUpdate';
      dispatch(setIsLoading(true));
      await apiFetch(action, studentIdQrData);
      dispatch(setIsLoading(false));

      // this contains a reset for next scan
      await stopQrScanner();
      setIsReadyToStart(true);
    }
  };

  useEffect(() => {
    if (user && scannedUserId && scannedUserHash && scannedUserDate) {
      confirmStudentIdScan(scannedUserId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedUserId, scannedUserHash, scannedUserDate]);

  useEffect(() => {
    if (selectedDojo.id && isReadyToStart) {
      startQrScanner();
    }
  }, [isReadyToStart]);

  useEffect(() => {
    if (selectedDojo.id > 0) {
      setScannedUserMessage(`${selectedDojo.name} selected for today's class`);
      setIsReadyToStart(true);
    }
  }, [selectedDojo]);

  useEffect(() => {
    if (dojos && dojos.length > 0) {
      setError(`Please select Dojo before scanning`);
    }
  }, [dojos]);

  useEffect(() => {
    if (user) {
      // eslint-disable-next-line react/prop-types
      getDojos(user.email, user.uid);
      if (!scannerRef.current) {  
        sizeScanFrame(); 
        const scanner = new Html5Qrcode('reader');
        scannerRef.current = scanner;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullScreenWrapper>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className={styles.formComponents}>
          <h1 className={`shadow`}>QR Code Scanner</h1>

          <label htmlFor="actionSelector">Dojo:</label>
          <select
            id="actionSelector"
            value={selectedDojo.id}
            onChange={handleDojoChange}
          >
            <option key="0" value="0" disabled>
              Please select Dojo
            </option>
            {dojos.map((dojo) => (
              <option key={dojo.id} value={dojo.id}>
                {dojo.name}
              </option>
            ))}
          </select>

          <div className={styles.qrContainer}>
            <div id="reader" style={{ width: `${scanWidth}px`, height: `${scanHeight}px` }} />
          </div>

          <br /><br />

          <div onClick={() => confirmStudent()} style={{ cursor: scannedUserId ? "pointer" : "not-allowed" }}>
            <div className={styles.infoContainerTop}>
              <div className={styles.infoMargin}>
                <span className={styles.infoTop}>
                  {scannedUserMessage &&
                    <div>
                      {scannedUserMessage}
                    </div>
                  }
                </span>
              </div>
            </div>
            <div className={styles.infoContainerBottom}>
              <div className={styles.infoMargin}>
                <span className={styles.infoBottom}>
                  {error &&
                    <>{error}</>
                  }
                </span>
              </div>
            </div>
          </div>

          <br /><br />
            
          <button type="submit" className={styles.closeButton} onClick={handleClose}>
            <span className="shadow">Close</span>
          </button>

        </div>
      )}
    </FullScreenWrapper>
  );
};

export default QRCodeScanner;
