import React, { useState } from "react";
import { useSelector } from 'react-redux';
//import { auth } from './firebase';
//import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from "react-router-dom";

import LoadingScreen from '../components/screen/Loading';
import FullScreenWrapper from "../components/utils/FullscreenWrapper";

import { FaTimes } from 'react-icons/fa';
import styles from '../styles/Profile.module.scss';

const NewUser = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState('');

  const [captchaToken, setCaptchaToken] = useState(null);

  const isLoading = useSelector((state) => state.loading.isLoading);

  const signupCancel = async (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const signupWithUsernameAndPassword = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    if (password === confirmPassword) {
      try {
        //const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        //const user = userCredential.user;
        //await sendEmailVerification(user); // firebase verification email
        //await notifyAdmin(email);
        //navigate("/");
        setError("Verification email sent! Please check your email to verify your account. You can now close this sign-up screen.");
      } catch {
        setError("Sorry, something went wrong. Please try again.");
      }     
    } else {
      setError("Passwords don't match. Please try again.");
    }
    /*
      });
    });
    */
  };

  return(
    <FullScreenWrapper>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <></>
      )}
        <div className={styles.formComponents}>
          <h1 className={`shadow`}>Sign-up</h1>
          <div className={styles.container}>

            <p>
              Sign up here to login to the app. Note that you will need to verify your e-mail address to complete sign-up successfully.
              <br /><br />
            </p>

            <label htmlFor="signupEmail" className={styles.inputLabel}>
              Email address<br />
              <input
                type="email"
                id="signupEmail"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ borderColor: 'green' }}
                required
                autoComplete="new-email"
              />
            </label>

            <label htmlFor="signupPassword" className={styles.inputLabel}>
              Password<br />
              <input
                type="password"
                id="signupPassword"
                placeholder="Password"
                value={password}
                onChange={ (e) => setPassword(e.target.value) }
                autoComplete="new-password"
              />
            </label>

            <label htmlFor="confirmPassword" className={styles.inputLabel}>
              Confirm Password<br />
              <input
                type="password"
                id="confirmPassword"
                placeholder="Password"
                value={confirmPassword}
                onChange={ (e) => setConfirmPassword(e.target.value) }
              />
            </label>

            <ReCAPTCHA
              sitekey="6LccdmsqAAAAAEP_L9sosp1slfNToPWIZdJbhVxP"
              onChange={handleCaptchaChange}
            />

            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.cancelButton} onClick={(e) => signupCancel(e)}>
                <span className="shadow">Close</span>
              </button>

              <button type="submit" className={styles.button} onClick={(e) => signupWithUsernameAndPassword(e)}>
                <span className="shadow">Sign-up</span>
              </button>
            </div>

            <div className={`${error ? styles.errorContainer : styles.errorPlaceholder}`}>
              {error ? (
                <><FaTimes className={styles.icon} /><span className={styles.error}>{error}</span></>
              ) : (
                <></>
              )}
            </div>

          </div>

        </div>
    </FullScreenWrapper>
  )
}

export default NewUser