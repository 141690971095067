import React, { useRef, useState } from "react";
import QRCode from "qrcode";

import { FaQrcode, FaRedoAlt } from 'react-icons/fa';
import styles from '../../styles/Profile.module.scss';

// eslint-disable-next-line react/prop-types
const QRCodeGenerator = ({ firstname, token, date }) => {
  const canvasRef = useRef(null);
  const [qrGenerated, setQrGenerated] = useState();

  const generateQRCode = async () => {
    const data = `${firstname} ${token} ${date}`;
    const canvas = canvasRef.current;

    try {
      await QRCode.toCanvas(canvas, data, {
        errorCorrectionLevel: "H",
        margin: 2,
        width: 200,
      });      
      setQrGenerated(true);
    } catch (error) {
      setQrGenerated(false);
      console.error("Error generating QR Code:", error);
    }
  };

  return (
    <>
      <canvas ref={canvasRef} />
      {!qrGenerated &&
        <button onClick={generateQRCode}><FaRedoAlt className={styles.icon} />&nbsp;<FaQrcode className={styles.icon} /></button>
      }  
    </>
  );
};

export default QRCodeGenerator;
