/* TODO: Re-work post hackerizing */

import React from 'react';
import { FaMapMarkerAlt, FaClock, FaEnvelopeOpenText, FaStar } from 'react-icons/fa';
import IconMessage from '../utils/iconMessage';

import { useNavigate } from 'react-router-dom';

import styles from '../../styles/Login.module.scss';

const FinishedRegistration = () => {
  const navigate = useNavigate();

  // TODO: Create a local storage var flag for sign-up so we don't show it each time if a user has already completed it

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <>
      <form>
        <div className={styles.inputBox}>
          <div className={styles.loginButtonContainer}>
            <button type="submit" className={styles.authButton} onClick={handleHomeClick}>
              <span className="shadow">Home</span>
            </button>
          </div>
        </div>
      </form>
      <div style={{height: '1em'}} />

      <div className="byOneGrid">

        <IconMessage
          icon={FaEnvelopeOpenText}
          heading="Sign up Done"
          description="Congratulations on sign up. We'll flick you an email as confirmation and to finish getting you setup."
        />

        <IconMessage
          icon={FaStar}
          heading="FIRST WEEK FREE!"
          description="Come along and meet us for one week, and the lessons are FREE! See locations & times below."
          type="offer"
        />
          
        <div className="twoByTwoGrid">

          <a href="/" className={`menuItem`}>
            <FaMapMarkerAlt className={`icon`} />
            <h2 className={`shadow`}>Dojo locations</h2>
          </a>

          <a href="/" className={`menuItem`}>
            <FaClock className={`icon`} />
            <h2 className={`shadow`}>Class times</h2>
          </a>

        </div>   

      </div>
    </>
  );
};

export default FinishedRegistration;
