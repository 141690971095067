import React from 'react';

import styles from '../../styles/Profile.module.scss';

// eslint-disable-next-line react/prop-types
const MessageTable = ({ unsentMessages, handleSend }) => {
  // eslint-disable-next-line react/prop-types
  const unsentMessageCount = unsentMessages.length || 0;
  return (
    <>
      <div className={`messageGrid`}>
        <div className={`messageGridHeadings`}>
          <h2>TITLE</h2><h2>CONTENT</h2><h2>CREATED AT</h2><h2>ACTIONS</h2>
        </div>             
        {(unsentMessages && unsentMessageCount > 0) ? (
          // eslint-disable-next-line react/prop-types
          unsentMessages.map((message) => (
            <div key={message.id} className={`messageRow`}>
              <p>{message.title}</p>
              <p>{message.content}</p>
              <p className={styles.sentDate}>{new Date(message.created_date).toLocaleString()}</p>
              <button className={styles.closeButton} onClick={() => handleSend(message)}>
                Send
              </button>
            </div>
          ))
        ) : (
          <>{/* TODO: Maybe add some kind of cute emoji & accompanying message here */}</>
        )}
      </div>
    </>
  );
};

export default MessageTable;