import { configureStore } from '@reduxjs/toolkit';
import featuresSlice from './featuresSlice';
import loadingReducer from './loadingSlice';
import registeringReducer from './registeringSlice';
import authReducer from './authSlice';
import signUpReducer from './signUpSlice';
import studentReducer from './studentInfoSlice';
import cardIdReducer from './cardIdSlice';
import settingsReducer from './settingsSlice';
import messageReducer from './messageSlice';
import errorReducer from './errorSlice';

const store = configureStore({
  reducer: {
    feature: featuresSlice,
    loading: loadingReducer,
    registering: registeringReducer,
    auth: authReducer,
    signUp: signUpReducer,
    students: studentReducer,
    cardId: cardIdReducer,
    settings: settingsReducer,
    messages: messageReducer,
    error: errorReducer,
  },
});

export default store;
